html,
body,
#redwood-app,
#redwood-app > div {
  overflow: auto;
  overflow-x: hidden;
}

body {
  margin: 0;
}

.PSPDFKit-Resize-Anchor {
  display: none !important;
}

#__filestack-picker {
  width: 100%;
}

.overflow-visible {
  overflow: visible !important;
}

.loading-color path {
  fill: #1d4474;
  stroke: #1d4474;
}

.pointed {
  cursor: pointer;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.align-items-center {
  display: flex !important;
  align-items: center !important;
}

.small-font {
  font-size: 1em !important;
}

.full-screen-height {
  height: max-content;
}
.full-height {
  height: max-content;
}

.full-screen-width {
  width: 100% !important;
}
.full-width {
  width: 100% !important;
}

.nav-link {
  color: white;
  font-size: 1.5rem;
}

.dashboard-photo-min {
  min-height: 204px;
}

body > div,
body > div > div,
body > div > div > .ui.grid {
  height: 100%;
}

.b-none {
  border: none !important;
}

.link {
  /* identical to box height, or 150% */
  text-decoration-line: underline;

  /* Primary */
  color: #379ecc;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.site-body-height {
  height: max-content;
}

.site-body-width {
  width: 100%;
}

.with-sidebar {
  width: calc(100% - 200px);
}

.site-body-background {
  height: 100%;
  padding-bottom: 2rem;
  background-color: #e4eef2;
}

/* Heights */
.h-1 {
  height: 1rem;
}
.h-2 {
  height: 2rem;
}
.h-3 {
  height: 3rem;
}
.h-4 {
  height: 4rem;
}
.h-5 {
  height: 5rem;
}
.h-6 {
  height: 6rem;
}
.h-7 {
  height: 7rem;
}
.h-8 {
  height: 8rem;
}
.h-9 {
  height: 9rem;
}
.h-10 {
  height: 10rem;
}
.h-11 {
  height: 11rem;
}
.h-12 {
  height: 12rem;
}

/* Widths */
.w-1 {
  width: 1rem;
}
.w-2 {
  width: 2rem;
}
.w-3 {
  width: 3rem;
}
.w-4 {
  width: 4rem;
}
.w-5 {
  width: 5rem;
}
.w-6 {
  width: 6rem;
}
.w-7 {
  width: 7rem;
}
.w-8 {
  width: 8rem;
}
.w-9 {
  width: 9rem;
}
.w-10 {
  width: 10rem;
}
.w-11 {
  width: 11rem;
}
.w-12 {
  width: 12rem;
}

/* margins */
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}
.m-5 {
  margin: 5rem;
}
/* Margin Top */
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
/* Margin Bottom */
.mb-half {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
/* Margin Right */
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mr-5 {
  margin-right: 5rem;
}
/* Margin Left */
.ml-0 {
  margin-left: 0rem;
}
.ml-half {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}

/* paddings */
.p-0 {
  padding: 0rem;
}
.p-half {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}
.p-5 {
  padding: 5rem;
}
.p-6 {
  padding: 6rem;
}
.p-7 {
  padding: 7rem;
}
.p-8 {
  padding: 8rem;
}
.p-9 {
  padding: 9rem;
}
.p-10 {
  padding: 10rem;
}
/* padding Top */
.pt-0 {
  padding-top: 0rem;
}
.pt-half {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pt-6 {
  padding-top: 6rem;
}
.pt-7 {
  padding-top: 7rem;
}
.pt-8 {
  padding-top: 8rem;
}
.pt-9 {
  padding-top: 9rem;
}
.pt-10 {
  padding-top: 10rem;
}
/* padding Bottom */
.pb-0 {
  padding-bottom: 0rem;
}
.pb-half {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.pb-5 {
  padding-bottom: 5rem;
}
.pb-6 {
  padding-bottom: 6rem;
}
.pb-7 {
  padding-bottom: 7rem;
}
.pb-8 {
  padding-bottom: 8rem;
}
.pb-9 {
  padding-bottom: 9rem;
}
.pb-10 {
  padding-bottom: 10rem;
}
/* padding Right */
.pr-0 {
  padding-right: 0rem;
}
.pr-half {
  padding-right: 0.5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-4 {
  padding-right: 4rem;
}
.pr-5 {
  padding-right: 5rem;
}
.pr-6 {
  padding-right: 6rem;
}
.pr-7 {
  padding-right: 7rem;
}
.pr-8 {
  padding-right: 8rem;
}
.pr-9 {
  padding-right: 9rem;
}
.pr-10 {
  padding-right: 10rem;
}
/* padding Left */
.pl-0 {
  padding-left: 0rem;
}
.pl-half {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pl-5 {
  padding-left: 5rem;
}
.pl-6 {
  padding-left: 6rem;
}
.pl-7 {
  padding-left: 7rem;
}
.pl-8 {
  padding-left: 8rem;
}
.pl-9 {
  padding-left: 9rem;
}
.pl-10 {
  padding-left: 10rem;
}

/* Select CSS Overrides */
.dd-wrapper {
  user-select: none;
  position: relative;
  background-color: none;
  width: 100%;
}
.dd-wrapper .dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 38px;
  border: None;
  cursor: default;
  position: relative;
  box-sizing: border-box;

  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #001733;
}

.dd-header:not(:last-child) {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dd-wrapper .dd-header span {
  margin-right: 20px;
}
.dd-wrapper .dd-header-title {
  font-weight: 300;
  margin-right: 30px;
}
.dd-wrapper .angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-wrapper .dd-list {
  z-index: 10;
  position: absolute;
  width: 100%;
  border-top: none;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #fff;
  padding: 0 0;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: 700;
  max-height: 215px;
  overflow-y: scroll;
  border: none;
  -webkit-overflow-scrolling: touch;
}
.dd-wrapper .dd-list-item {
  width: 100%;
  font-size: 1.5rem;
  padding: 8px 10px;
  box-sizing: padding-box;
  line-height: 1.6rem;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top: 1px solid #379ecc;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #001733;
}

.dd-wrapper .dd-list-item.selected {
  color: #fff;
  background-color: #379ecc;
}
.dd-wrapper .dd-list-item:hover {
  color: #fff;
  background-color: #379ecc;
}

/* Fullscreen */
.fullscreen {
  display: flex;
  flex-direction: row;
  background: white;
}

.PSPDFKit-Resize-Anchor {
  display: none !important;
}
