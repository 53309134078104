iframe body {

  background: none!important;
}

.PSPDFKit-Viewport {
  background: none!important;
}

.PSPDFKit-Resize-Anchor {
  display: none!important;
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover {
  background-color: transparent;
}

.PSPDFKit-Popover-Annotation-Tooltip .annotation-tooltip-container {
  background-color: transparent;
  border: none;
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover-Arrow {
  color: #f4f6f9;
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover-Arrow-Top {
  color: #f4f6f9;
  transform: translateY(-1px);
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover-Arrow-Bottom {
  color: rgb(216, 223, 235);
  transform: translateY(1px);
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover-Arrow-Right {
  transform: translateX(7px);
}

.PSPDFKit-Popover-Annotation-Tooltip .PSPDFKit-Popover-Arrow-Left {
  transform: translateX(-6px);
}

.PSPDFKit-Popover-Note-Annotation .annotation-inspector {
  min-width: 280px;
}

.PSPDFKit-Popover-Note-Annotation .annotation-inspector,
.PSPDFKit-Popover-Note-Annotation .annotation-inspector section {
  background-color: transparent;
}

.annotation-inspector {
  font-size: 0.75em;
  background-color: #fcfdfe;
  border-radius: 5px;
  font-size: 0.75rem;
  min-width: 300px;
  padding-bottom: 1rem;
  pointer-events: all;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

.annotation-inspector header {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: rgba(216, 223, 235, 0.5);
  border-radius: 5px 5px 0px 0px;
}

.annotation-inspector h1 {
  color: #848c9a;
  font-size: 0.75rem;
  font-weight: normal;
}
.annotation-inspector section {
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 243, 249, 0.5);
}

.annotation-inspector .section-title {
  align-self: flex-end;
  margin: 6px 0.4rem 6px 0;
}

.annotation-inspector .group-title {
  color: #2b2e36;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.PSPDFKit-Popover-Annotation-Tooltip .annotation-inspector,
.PSPDFKit-Popover-Annotation-Tooltip .annotation-inspector {
  /* .PSPDFkit-Popover-Annotation-Tooltip is added to avoid
  adding the box-shadow property to note annotations */
  box-shadow: 2px 2px 3px rgba(120, 120, 120, 0.3);
}

.annotation-inspector ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}

.annotation-inspector li {
  display: flex;
  align-items: center;
  text-align: left;
  color: #555;
  background-color: #fcfdfe;
  box-shadow: inset 0px -0.5px 0px #e5e9f1;
  border-bottom: solid 0.25px rgba(216, 223, 235, 0.5);
  padding: 0.375rem 0.75rem;
}

.PSPDFKit-Popover-Note-Annotation .annotation-inspector {
  padding-bottom: 0;
}

.PSPDFKit-Popover-Note-Annotation .annotation-inspector header {
  display: none; /* Don't show header for note annotations */
}
.PSPDFKit-Popover-Note-Annotation .annotation-inspector ul {
  /* lists should be always visible for note annotations */
  display: block;
}

.PSPDFKit-Popover-Note-Annotation .annotation-inspector li {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0.2rem 0.75rem;
}

.PSPDFKit-Popover-Note-Annotation .section-title {
  display: none; /* Don't show section titles for note annotations */
}

.collapsed-list {
  display: none;
}

.annotation-inspector li span {
  flex: 1;
}

.annotation-inspector li span.attr {
  color: #3d424e;
  display: flex;
  align-items: center;
}

.annotation-inspector .property-icon {
  margin-right: 0.75rem;
}

.annotation-inspector .val {
  color: #2b2e36;
  font-weight: bold;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 28px;
}

.annotation-inspector .val > * {
  font-weight: bold;
  font-size: 0.875rem;
  color: #2b2e36;
}

.annotation-inspector .annotation-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: white;
  padding: 0.25rem 0.75rem;
  box-shadow: 0px 0.75px 1.5px rgba(0, 0, 0, 0.15);
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.66' d='M4 4L0 0h8L4 4z' fill='%233D424E'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 95%;
  border: none;
  width: 85%;
}

/* IE11 */
.annotation-inspector .annotation-select::-ms-expand {
  display: none;
}

.collapse-toggle-button {
  border: none;
  background: none;
  display: block;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.annotation-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.3rem;
  width: 4em;
  text-align: right;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5),
    inset 0px 0.75px 1.5px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 3px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #fcfdfe;
  font-size: 0.875rem;
}

.annotation-range {
  width: 50%;
  padding: 0;
  margin-right: 0.75rem;
}

.switch-form input {
  display: none;
}

.switch-form label {
  box-shadow: 0px 0.75px 1.5px rgba(0, 0, 0, 0.35);
  background-color: white;
  display: inline-block;
  padding: 0.125rem 0.25rem;
  width: 24px;
  height: 24px;
  box-sizing: content-box;
}

.switch-form label:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.switch-form label:first-of-type {
  border-radius: 3px 0px 0px 3px;
}

.switch-form label:last-of-type {
  border-radius: 0px 3px 3px 0px;
}

.switch-form input:checked + label {
  background: #4636e3;
  box-shadow: inset 0px 0.5px 0.5px rgba(0, 0, 0, 0.25);
}

.switch-form label img {
  pointer-events: none;
}

.switch-form input:checked + label > img {
  filter: invert(1);
}
